import React, { Fragment } from 'react';
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import moment from 'moment-timezone';
import Helmet from 'react-helmet'
import { rhythm } from "../utils/typography"
import { useQueryParam, StringParam } from "use-query-params"
import gamePlayBtn from "../img/btn-play.png";
import Layout from "../components/layout"
const $ = require("jquery")

const propTypes = {
  data: PropTypes.object.isRequired,
}

function itemSort() {
  let ctg = $('input[name="ctg"]:checked').val();
  let rank = $('input[name="rank"]:checked').val();
  let baseSelector = 'ul.l-nft-section-list li';
  let sortSelector = '';

  //invisible rank "used" when selected category for "cart".
  if( ctg == 'cart' ) {
    $('#rank-new').removeClass('disnon');
  } else {
    $('#rank-new').addClass('disnon');
  }

  if( ctg ) sortSelector = sortSelector+'.'+ctg;
  if( rank ) sortSelector = sortSelector+'.'+rank;
  let selected = $(baseSelector + sortSelector);
  $(baseSelector).not(sortSelector).addClass('disnon');
  selected.removeClass('disnon');
  $('#current').text(selected.length);
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

const News = ({ node }) => {
  //const dateDisplay = node.createdAt.split('T')[0].replace(/-/g,'.');
  const dateDisplay = node.displayDate.split('T')[0].replace(/-/g,'.');
  return (
  <div>
    <Link
      style={{ color: `#4E2714`, textDecoration: `none`, fontSize: "1.5rem", fontWeight: 600 }}
      to={`/${getLangDir(node.node_locale)}/news/${node.slug}/`}
    >
      <div
        style={{
          display: `flex`,
          alignItems: `center`,
          fontSize: 14,
          paddingBottom: rhythm(1),
          marginBottom: rhythm(1),
        }}
      >
        <div className="news-list-day">{dateDisplay}</div>
        <div className="news-list-title">{node.title}</div>
      </div>
    </Link>
  </div>
  )
}

const GameLink = () => {
  let gameLink = "https://game.cookinburger.com";
  const [rid, setRid] = useQueryParam('rid', StringParam);

  if (typeof window !== 'undefined') {
    if (rid === undefined && localStorage.getItem("rid") !== null) {
      gameLink = gameLink+"?rid="+localStorage.getItem('rid');
    } else {
      gameLink = rid !== undefined ? gameLink+"?rid="+rid : gameLink;
    }
  }
  return (
    <a onClick={() => handleClick()} className="game_btn_link" href={gameLink} target="_blank">
      <div className="btn-play"><img src={gamePlayBtn} alt="Click to start!" /></div>
    </a>
  )
}

function handleClick() {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem("rid") !== null) {
      var labelValue = '';
      var rId = '';
      var deferCampaignLog = new $.Deferred().resolve();
      deferCampaignLog.promise()
        .then(function() {
            rId = localStorage.getItem("rid");
            return rId
        })
        .then(function(rId) {
            if (rId === null) {
                rId = 'organic'
            }
            labelValue = rId+' | '+localStorage.getItem("cookinburger_guid_raw")+'-'+rId+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
            dataLayer.push({
                'event' : 'gaEvent',
                'eventCategory': 'Conversion: Campaign',
                'eventAction': 'click',
                'eventLabel': labelValue
            });
            var dataCampaignObj = {
                "Property": 'cookinburger',
                "Guid": localStorage.getItem("cookinburger_guid_raw")+'-'+rId,
                "Rid": rId,
                "Locale": window.pmApp.checkLocale(window.location.href),
                "Lp": window.location.href,
                "Reg": "0",
                "FT" : "0",
                "Conv": "1",
                "Username" : 'n/a',
                "Email": 'n/a',
                "Country": 'n/a',
                "City": 'n/a',
                "LonLat": 'n/a',
                "Ip": 'n/a',
                "Timezone": 'n/a',
                "Ref": localStorage.getItem("ref") !== null ? localStorage.getItem("ref") : 'n/a',
                "Lang": language
            };
            if (localStorage.getItem("game_conv_rid") === null) {
                window.pmApp.sendCampaignLog(dataCampaignObj);
            } else {
                var convDoneArray = localStorage.getItem("game_conv_rid");
                convDoneArray = convDoneArray.split(",");
                if (convDoneArray.indexOf(rId) === -1) {
                    window.pmApp.sendCampaignLog(dataCampaignObj);
                }
            }
        })
        .done(function() {
            return true;
        })
    } else {
        labelValue = 'organic | '+localStorage.getItem("cookinburger_guid_raw")+'-organic'+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
        dataLayer.push({
            'event' : 'gaEvent',
            'eventCategory': 'Conversion: Organic',
            'eventAction': 'click',
            'eventLabel': labelValue
        });
    }
  }
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        error: false,
        fetchedData: [],
        progress: false,
        dataAvail: false,
    };
  }

  componentDidMount() {
    // const AuthStr = process.env.GATSBY_LUCKY_TOKEN;
    // fetch("https://api-luckyfarmer.playmining.com/api/v1/service/last_ranking_result", { headers: { 'Authorization': AuthStr } })
    // .then(response => {
    //   return response.json()
    // })
    // .then(json => {
    //   console.log('json:', json);
    //     if (json.common.code === 403006) {
    //       this.setState({
    //         progress: true,
    //         loading: false
    //       });
    //     } else if (json.common.code === 0) {
    //       this.setState({
    //         fetchedData: json.data,
    //         loading: false,
    //         dataAvail: true,
    //       });
    //     }
    //   },
    // )

    const total = $('ul.l-nft-section-list li');
    $('#total').text(total.length);
    itemSort();

    $('input[name="ctg"]').on('change', function() {
      itemSort();
    });

    $('input[name="rank"]').on('change', function() {
      itemSort();
    });
  }

  shuffleArticle = (data, topNews) => {
    let articles = [], fixTopIndex = null;
    const numLength = topNews && topNews.length > 0 ? data.length - topNews.length : data.length;
    for (let i=0; i<numLength; i++) {
      if (data[i]['node']['positionTopFixed'] === null || data[i]['node']['positionTopFixed'] === false) {
        articles.push(data[i]);
      } else if (data[i]['node']['positionTopFixed'] == true) {
        fixTopIndex = i;
      }
    }
    if (fixTopIndex !== null) {
      articles.unshift(data[fixTopIndex]);
    }
    return articles;
  }

  render() {
    const jaTopNews = this.props.data.jaTop.edges;
    var jaNewsEdges = [];
    if (this.props.data.ja !== null) {
      jaNewsEdges = this.shuffleArticle(this.props.data.ja.edges, jaTopNews);
    }
    // const users = this.props.data.user !== undefined ? this.props.data.user.edges : false;
    const { loading, fetchedData, progress, dataAvail } = this.state;
    return (
      <Layout data={this.props.data} location={this.props.location} key={loading}>
        <Helmet>
          <title>Cookin’Burger │ ゲームを遊ぶとDEAPcoinが獲得できる!マルチタスク料理ゲーム</title>
          <meta name="description" content="PlayMining プラットフォーム新タイトル! マルチタスク料理ゲーム!" />
          <meta name="keywords” content=”DEA,DEAP,DEP,blockchain,ブロックチェーン,PlayMining" />
          <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
          <meta property="og:title" content="Cookin' Burgerで遊んでいるよ！" />
          <meta property="og:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin' Burger」でDEAPcoinがゲットできちゃう！？気になった人はさっそく一緒に遊んでみよう！" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Cookin' Burgerで遊んでいるよ！" />
          <meta name="twitter:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin' Burger」でDEAPcoinがゲットできちゃう！？気になった人はさっそく一緒に遊んでみよう！" />
          <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
        </Helmet>
        <div className="l-main">

          <div className="l-key-visual">
            <div className="section-inner_m">
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-key-visual-logo wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img-ja/logo-cb.png" alt="2022年春サービス開始決定！" />
              </div>
              <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="700" data-sal-easing="ease"
               className="l-key-visual-title wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                <img className="l-key-visual-title-logo" src="../../assets/img-ja/playmining-logo.png" alt="playmining" />
                <img src="../../assets/img-ja/key-visual-title.png" alt="New PlayMining Platform Game Cookin' Burger Set to launch in Spring 2022!" />
                {/* <a href="https://game.cookinburger.com/" target="_blank"><div className="btn-play">
                  <img src="../../assets/img-ja/btn-play.png" alt="今すぐプレイする" /></div></a> */}
                <GameLink />
              </div>
              <div data-sal="slide-up" data-sal-delay="400" data-sal-duration="700" data-sal-easing="ease"
               className="l-key-visual-item wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img-ja/key-visual-item.png" alt="" />
              </div>
            </div>

            <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
             className="sp-none section-banner-en wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
              <ul>
                {/* <li><a href="https://daa.playmining.com/market?keyword=%E3%80%90SOUL%20Fusers%E3%80%91&isKeyword=1&page=1" target="_blank"><img src="../../assets/img-ja/SOULFuser_presale_ja.png" alt="PlayMiningNFT NFT購入はこちらから" /></a></li> */}
                <li><a href="https://daa.playmining.com/" target="_blank"><img src="../../assets/img-ja/banner-playmining_ja.jpg" alt="PlayMiningNFT NFT購入はこちらから" /></a></li>
                <li><a href="https://www.bitpoint.co.jp/lp/cpn03/?afid=fXb7Fe3x&utm_source=deasg&utm_medium=bpjweb&utm_campaign=non&utm_content=banner&argument=E4y65Xyx&dmai=a6315c77f5de95" target="_blank"><img src="../../assets/img-ja/banner-bitpoint.jpg" alt="bitpoint" /></a></li>
                <li>
                  <a href="https://www.okcoin.jp/" target="_blank">
                    <div style={{
                      backgroundColor: "white",
                      display: "inline-block",
                      width: "275px",
                      height: "105px",
                      overflow: "hidden", // 余白を隠す
                      position: "relative",
                    }}>
                      <img src="../../assets/img-ja/banner-okj.jpg" alt="okcoin" style={{
                        display: "block",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        width: "auto",
                        height: "100%",
                        transform: "translate(-50%, -50%)", // 縦横センターに配置
                       }} />
                    </div>
                  </a>
                </li>
                <li><a href="https://www.bittrade.co.jp/ja-jp/" target="_blank"><img src="../../assets/img-ja/exchange/banner-huobi.jpg" alt="bittrade" /></a></li>
                <li><a href="https://zaif.jp/" target="_blank"><img src="../../assets/img-ja/banner-zaif.jpg" alt="Zaif" /></a></li>
              </ul>
            </div>
          </div>

          <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
           className="pc-none section-banner-ja wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
            <ul>
              {/* <li><a href="https://daa.playmining.com/market?keyword=%E3%80%90SOUL%20Fusers%E3%80%91&isKeyword=1&page=1" target="_blank"><img src="../../assets/img-ja/SOULFuser_presale_ja.png" alt="PlayMiningNFT NFT購入はこちらから" /></a></li> */}
              <li><a href="https://daa.playmining.com/" target="_blank"><img src="../../assets/img-ja/banner-playmining_ja.jpg" alt="PlayMiningNFT NFT購入はこちらから" /></a></li>
              <li><a href="https://www.bitpoint.co.jp/lp/cpn03/?afid=fXb7Fe3x&utm_source=deasg&utm_medium=bpjweb&utm_campaign=non&utm_content=banner&argument=E4y65Xyx&dmai=a6315c77f5de95" target="_blank"><img src="../../assets/img-ja/banner-bitpoint.jpg" alt="bitpoint" /></a></li>
              <li><a href="https://www.okcoin.jp/" target="_blank"><img src="../../assets/img-ja/banner-okj.jpg" alt="okcoin" /></a></li>
              <li><a href="https://www.bittrade.co.jp/ja-jp/" target="_blank"><img src="../../assets/img-ja/exchange/banner-huobi.jpg" alt="bittrade" /></a></li>
              <li><a href="https://zaif.jp/" target="_blank"><img src="../../assets/img-ja/banner-zaif.jpg" alt="Zaif" /></a></li>
            </ul>
          </div>

          <div className="section-inner_m">
            <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
             className="l-news-section wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <ul className="news-list">
                  {jaTopNews.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                  {jaNewsEdges.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                </ul>
                <Link to={`/ja/news/`}><div className="news-list-btn">More</div></Link>
            </div>
          </div>

          <div className="l-nft-section">
            <div className="section-inner_m">
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-nft-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img-ja/title-01.png" alt="ショップNFTを先行販売中！" />
              </div>
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-nft-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img-ja/title-sub-01.png" alt="いち早く開店準備ができるチャンス！ショップオーナーになってライバルに差をつけよう！" />
              </div>
              <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-nft-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                『Cookin’ Burger』を有利に遊ぶことができるNFTを、<br />PlayMining NFTで好評発売中！
              </p>

              <div className="l-nft-section-sort-rank-btn">
              <Link style={{ color: `#FFF`}} to={`/ja/repairrank/`}>
                修繕ランクについて
                <span className="rank-new">NEW</span></Link>
              </div>

              <div className="l-nft-section-sort">
                <div className="l-nft-section-sort-ctg">
                  <div className="l-nft-section-sort-title">カテゴリ</div>
                  <div className="l-nft-section-sort-item-sc">
                    <div className="l-nft-section-sort-item">
                      <input className="visually-hidden" type="radio" name="ctg" value="" id="ctg-all"/>
                      <label for="ctg-all">すべて</label>
                      <input className="visually-hidden" type="radio" name="ctg" value="cart" id="ctg-01" defaultChecked />
                      <label for="ctg-01"><span className="ico-stall"></span>屋台</label>
                      <input className="visually-hidden" type="radio" name="ctg" value="truck" id="ctg-02"/>
                      <label for="ctg-02"><span className="ico-truck"></span>トラック</label>
                    </div>
                  </div>
                </div>
                  <div className="l-nft-section-sort-rank">
                    <div className="l-nft-section-sort-title">ランク</div>
                    <div className="l-nft-section-sort-item-sc">
                      <div className="l-nft-section-sort-item">
                        <input className="visually-hidden" type="radio" name="rank" value="" id="rank-all"/>
                        <label for="rank-all">すべて</label>
                        <input className="visually-hidden" type="radio" name="rank" value="used" id="rank-01" />
                        <label for="rank-01">中 古</label>
                        <input className="visually-hidden" type="radio" name="rank" value="economy" id="rank-02" defaultChecked />
                        <label for="rank-02">エコノミー</label>
                        <input className="visually-hidden" type="radio" name="rank" value="deluxe" id="rank-03"/>
                        <label for="rank-03">デラックス</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="l-nft-section-list-txt">
                  <p>※ソートで表示を切り替えることが可能です。</p>
                  <div className="l-nft-section-list-txt-result">全<div id="total"></div> 枚中 - <span id="current"></span>枚表示</div>
                </div>

                <ul className="l-nft-section-list">
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1479.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1480.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1481.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1482.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1483.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1484.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1485.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1486.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1487.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>

                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1497.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1498.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1499.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCIDX</h3>
                        <a href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1500.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1501.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1502.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1503.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1504.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1505.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1555.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1556.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1557.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1558.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1559.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1560.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1561.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1562.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1563.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1591.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1592.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1593.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1594.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1595.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1596.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1597.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1598.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1599.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="soldout used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1609.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">完売</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1610.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1611.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1612.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1613.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1614.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1615.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1616.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1617.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>

                    {/* Newly Added */}
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1573.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1574.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1575.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1576.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1577.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1578.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1579.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1580.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1581.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">NFTを購入する</div></a>
                    </li>
                 </ul>
            </div>
          </div>

          <div className="l-about-section">
            <div className="section-inner_m">

              <div className="l-about-section-box">
                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img-ja/title-02.png" alt="Cookin’ Burger』とは？" />
                </div>

                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  『Cookin’ Burger』は、バーガーショップの店員として、<br />様々なタイプのお客のオーダーを受け、正確かつ素早く料理を提供して<br />お店の評判をあげることを目指すマルチタスク・アクションゲームです。<br />
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-ui wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img-ja/ui.png" alt="" />
                </div>
                <p className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  世界中の人々に馴染みの深いハンバーガーショップの経営を疑似体験し、<br />
                  シンプルなゲーム性ながら、お客さんの好みを覚えたり、より効率的な調理の順序を<br />
                  考えたりと、実際のお店を切り盛りするかのようなテクニックが要求されます。
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-img wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img-ja/about-section-img.png" alt="" />
                </div>

                <ul className="l-about-section-point">
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img-ja/icon-01.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                          ショップNFTを買うことでお店のオーナーになることが出来ます。お店には様々なグレードがあり、屋台など安価で料理手順のシンプルなものから、大型店舗などの高価で難易度も高いが大きなリターンが見込めるものまで、自分のレベルにあったお店にアップグレードしていくことが出来ます。
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img-ja/icon-02.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                          ショップNFTには『評判』というパラメータがあり、お客さんのオーダーや好みを覚えて素早く提供することで常連客が増えるとリピート率がアップして売上や評判が上がります。注文を間違えたり、焼き加減がいまいち、制限時間内に提供できない、などを繰り返すと評判が下がります。また、ゲームをプレイするたび『耐久値』というパラメーターが目減りし、ゼロになるとプレイできなくなります。
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img-ja/icon-03.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                          NFTを持っているオーナーは、アルバイト（スカラー）を雇ってゲームを自分の代わりにプレイしてもらうことが出来ます。アルバイト（スカラー）はゲームの腕を磨いてより多くのお客さんのオーダーをさばき、お店を繁盛させることによって他のお店からより良い条件でスカウトしてもらえるようになります。
                        </p>
                    </li>
                </ul>
              </div>

              <ul data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-footer-sns-list wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <li><a href="https://discord.gg/xWeHGdt" target="_blank"><img src="../../assets/img/sns-ico-dc.png" alt="" /></a></li>
                  <li><a href="https://twitter.com/PlayMining_SG" target="_blank"><img src="../../assets/img/sns-ico-x.png" alt="" /></a></li>
                  <li><a href="https://www.facebook.com/PlayMining/" target="_blank"><img src="../../assets/img/sns-ico-fb.png" alt="" /></a></li>
                  <li><a href="https://t.me/DEAPcoin_group" target="_blank"><img src="../../assets/img/sns-ico-tg.png" alt="" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCGWmK0RLV4SB_PSXpj2j6dw" target="_blank"><img src="../../assets/img/sns-ico-yt.png" alt="" /></a></li>
              </ul>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = propTypes

export default IndexPage

export const pageQuery = graphql`
  query PageDeQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    ja: allContentfulPost(filter: { node_locale: { eq: "ja" } }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
    jaTop: allContentfulPost(filter: { node_locale: { eq: "ja" }, positionTopFixed: { eq: true} }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
    # user: allRandomUser {
    #   edges {
    #     node {
    #       kind
    #       start
    #       end
    #       name {
    #         user_name
    #         value
    #         position
    #         dep
    #       }
    #     }
    #   }
    # }
  }
`
